












































import {Component} from 'vue-property-decorator';
import {Routes} from '@/router/Routes';
import {getModule} from 'vuex-module-decorators';
import UserModule from '@/store/modules/UserModule';
import ConnectionMixin from '@/mixins/ConnectionMixin';
import {ConnectionStatus} from '@/services/ConnectionService';
import {InstallationService} from '@/services/InstallationService';

@Component
export default class Navbar extends ConnectionMixin {
    private readonly userModule = getModule(UserModule, this.$store);
    readonly routes = Routes;

    private installReady: boolean = false;

    navbarVisible: boolean = false;

    get username(): string {
        return this.userModule.user?.username!;
    }

    get showInstallButton(): boolean {
        return this.installReady;
    }

    get connectionClass(): string {
        let classname: string;
        switch (this.connectionStatus) {
            case ConnectionStatus.GOOD:
                classname = 'bg-success';
                break;
            case ConnectionStatus.MEDIUM:
                classname = 'bg-danger';
                break;
            case ConnectionStatus.DATA_SAVE:
                classname = 'bg-warning';
                break;
            default:
                classname = 'bg-dark';
        }
        return classname;
    }

    get connectionTooltip(): string {
        let tooltip: string;
        switch (this.connectionStatus) {
            case ConnectionStatus.GOOD:
                tooltip = 'Online';
                break;
            case ConnectionStatus.MEDIUM:
                tooltip = 'Connection problems';
                break;
            case ConnectionStatus.DATA_SAVE:
                tooltip = 'Data saving mode';
                break;
            default:
                tooltip = 'Offline';
        }
        return tooltip;
    }

    async performLogout() {
        try {
            await this.userModule.logout();
        } catch (e) {
            // eslint-ignore-line
            console.log(e);
            return;
        }
        this.$router.push({name: Routes.LOGIN});
    }

    created() {
        InstallationService.installReady.then(() => {
            this.installReady = true;
        });
        InstallationService.appInstalled.then(() => {
            this.installReady = false;
        });
    }

    async installApp() {
        if (!this.installReady) {
            return;
        }

        try {
            await InstallationService.prompt();
        } catch (err) {
            /// eslint-ignore-line
            console.warn(err);
        }
    }
}
