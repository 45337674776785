import {Component, Vue} from 'vue-property-decorator';
import {ConnectionService, ConnectionStatus} from '@/services/ConnectionService';

@Component
export default class ConnectionMixin extends Vue {
    public connectionStatus: ConnectionStatus = ConnectionService.connectionStatus;
    private onConnectionChangeHandler = this.onConnectionChange.bind(this);

    created() {
        ConnectionService.addChangeListener(this.onConnectionChangeHandler);
    }

    destroyed() {
        ConnectionService.removeChangeListener(this.onConnectionChangeHandler);
    }

    private onConnectionChange(connectionStatus: ConnectionStatus) {
        this.connectionStatus = connectionStatus;
    }
}
