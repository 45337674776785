














import {Component, Vue} from 'vue-property-decorator';
import {getModule} from 'vuex-module-decorators';
import UserModule from '@/store/modules/UserModule';
import {Routes} from '@/router/Routes';
import Navbar from '@/components/Navbar.vue';
import ProductModule from '@/store/modules/ProductModule';
import {SocketService} from '@/services/SocketService';
import {persistencePlugin} from '@/store/plugins/PersistencePlugin';

@Component({
    components: {
        Navbar
    }
})
export default class Shell extends Vue {
    private readonly userModule = getModule(UserModule, this.$store);
    private readonly productModule = getModule(ProductModule, this.$store);
    private readonly routes = Routes;

    isLoading = true;

    async mounted() {
        await persistencePlugin.restored;

        if (this.$route.params.noValidate !== 'true') {
            try {
                await this.userModule.validateLogin();
            } catch (e) {
                this.$router.push({name: this.routes.LOGIN});
                return;
            }
        }

        try {
            await SocketService.connect(this.userModule.token!);
        } catch (e) {
            console.error('Cannot conntect to websocket. Got error:', e);
        }

        try {
            await Promise.all([
                this.productModule.refreshProducts(),
                this.productModule.refreshBrands(),
                this.productModule.refreshCategories()
            ]);
        } catch (err) {
            console.error(err);
        }

        this.isLoading = false;
    }
}
