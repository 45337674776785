import {SocketService} from '@/services/SocketService';

declare global {
    interface NavigatorNetworkInformation {
        mozConnection?: NetworkInformation,
        webkitConnection?: NetworkInformation
    }
}

type ConnectionChangeListener = (connectionStatus: ConnectionStatus) => any;

export enum ConnectionStatus {
    GOOD,
    DATA_SAVE,
    MEDIUM,
    BAD
}

class ConnectionService {
    private isOnline: boolean = window.navigator.onLine;
    private wsConnected: boolean = SocketService.isConnected;
    private isDataSaveMode: boolean = false;
    private changeListeners: ConnectionChangeListener[] = [];

    constructor() {
        window.addEventListener('online', () => {
            this.isOnline = true;
            this.triggerChangeEvent();
        });
        window.addEventListener('offline', () => {
            this.isOnline = false;
            this.triggerChangeEvent();
        });

        SocketService.on('disconnect', () => {
            this.wsConnected = false;
            this.triggerChangeEvent();
        });
        SocketService.on('connect', () => {
            this.wsConnected = true;
            this.triggerChangeEvent();
        });

        const networkInformation = window.navigator.connection || window.navigator.mozConnection || window.navigator.webkitConnection || null;
        if (networkInformation !== null) {
            this.isDataSaveMode = this.getDataSaveMode(networkInformation);
            networkInformation.addEventListener('change', () => {
                this.isDataSaveMode = this.getDataSaveMode(networkInformation);
                this.triggerChangeEvent();
            });
        }
    }

    addChangeListener(cb: ConnectionChangeListener) {
        this.changeListeners.push(cb);
    }

    removeChangeListener(cb: ConnectionChangeListener) {
        const index = this.changeListeners.indexOf(cb);
        if (index >= 0) {
            this.changeListeners.splice(index, 1);
        }
    }

    private triggerChangeEvent() {
        const status = this.connectionStatus;
        this.changeListeners.forEach(cb => {
            cb(status);
        });
    }

    private getDataSaveMode(networkInformation: NetworkInformation): boolean {
        return networkInformation.saveData || networkInformation.effectiveType === '2g' || networkInformation.effectiveType === 'slow-2g' || false;
    }

    get connectionStatus(): ConnectionStatus {
        if (!this.isOnline) {
            return ConnectionStatus.BAD;
        }
        if (!this.wsConnected) {
            return ConnectionStatus.MEDIUM;
        }
        if (this.isDataSaveMode) {
            return ConnectionStatus.DATA_SAVE;
        }
        return ConnectionStatus.GOOD;
    }
}

const connectionService = new ConnectionService();

export {connectionService as ConnectionService};
